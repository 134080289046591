import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,5];

export const dictionary = {
		"/(main)": [~6,[2]],
		"/api/craftcost/docs": [23],
		"/(main)/(protected)/dashboard": [~7,[2,3]],
		"/(main)/(protected)/dashboard/auctions": [~8,[2,3]],
		"/(main)/(protected)/dashboard/chats": [~9,[2,3]],
		"/(main)/(protected)/dashboard/minions": [~10,[2,3]],
		"/(main)/(protected)/dashboard/users": [~11,[2,3]],
		"/(main)/login": [~16,[2]],
		"/(misc)/partners": [18],
		"/(misc)/pricecheck": [~19],
		"/(main)/(protected)/profile/(profile)": [~12,[2,4]],
		"/(main)/(protected)/profile/(profile)/chats": [~13,[2,4]],
		"/(main)/(protected)/profile/(profile)/settings": [~14,[2,4,5]],
		"/(main)/(protected)/profile/(profile)/settings/notifications": [~15,[2,4,5]],
		"/(main)/signup/(protected)/password": [~17,[2]],
		"/(shared)/user/[user=username]": [~20],
		"/(shared)/user/[user=username]/(protected)/chat": [~21],
		"/(shared)/user/[user=username]/[minionID]": [~22]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';